import './fonts/font.css'
import { createGlobalStyle } from 'styled-components'
const GlobalStyle = createGlobalStyle`
  * {
      font-family :"Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
      box-sizing: border-box;
  }

    
`

export default GlobalStyle
