import {
	UserOutlined,
	SmileOutlined,
	CalendarOutlined,
	EnvironmentOutlined,
	MailOutlined,
	PhoneOutlined,
	BankOutlined,
	StarOutlined,
	PaperClipOutlined,
	TrophyOutlined,
	LaptopOutlined,
	SafetyOutlined,
} from '@ant-design/icons'
import { Card } from 'antd'
import useIntersectionObsever from 'hook/useIntersctionObserver'
import {
	AntdIcon,
	AwsIcon,
	CIcon,
	DockerIcon,
	FigmaIcon,
	GitHubIcon,
	GithubMiniIcon,
	GitlabIcon,
	HtmlIcon,
	JavaIcon,
	JiraIcon,
	MysqlIcon,
	OracleIcon,
	ReactIcon,
	ReduxIcon,
	StyledIcon,
	TistoryMiniIcon,
	TopBackGround,
	TsIcon,
	ZeplinIcon,
} from 'images'
import { useEffect, useLayoutEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { menuReset } from 'store/action/menuSlice'
import { styled } from 'styled-components'
import { ProjectList } from '../Projects/ProjectList'
import { CareerTimeLine } from '../Career/CareerTimeLine'

const InfoWrapper = styled.div`
	height: 100%;
`

const InfoHeaderWrapper = styled.div`
	padding-top: 80px;
	background-image: url(${TopBackGround});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 700px;
`

const ContentWrapper = styled.div`
	max-width: 1000px;
	margin: 0 auto;
	padding: 2rem 2rem 2rem 2rem;
`

const ContentFlexWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-flow: row wrap;
	gap: 7rem;

	@media screen and (max-width: 760px) {
		gap: 4rem;
	}
`

const SkillFlexWrapper = styled.div`
	@media screen and (max-width: 1200px) {
		height: 100rem;
	}

	@media screen and (max-width: 760px) {
		height: 180rem;
	}

	/* opacity: 0; */
	height: 70rem;
	display: flex;
	align-content: space-around;
	flex-flow: column wrap;
	gap: 6rem;

	&.animation {
		animation-name: opacity;
		animation-duration: 1500ms;

		@keyframes opacity {
			from {
				opacity: 0;
				transform: translate3d(0, 20%, 0);
			}
			to {
				opacity: 1;
				transform: translateZ(0);
			}
		}
	}
`

const ArchiveFlexWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-flow: row wrap;
	gap: 6rem;
`

const SkillWrapper = styled.div`
	background-color: #f9c51d;
`

const InfoAboutBox = styled.div`
	width: 10rem;
`

const InnerFlexDiv = styled.div`
	display: flex;
	font-size: 1.7rem;
	font-weight: 700;
	gap: 1rem;
`

const BlockDiv = styled.div`
	display: block;
`

const InnerHeaderDiv = styled.div`
	margin-bottom: 1rem;
`

const InnerContentDiv = styled.div`
	font-size: 1rem;
	font-weight: 400;
`

const InfoHeader = styled.div`
	margin-top: 5rem;
	height: 10rem;
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
`

const InfoContent = styled.div`
	height: 10rem;
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
`

const InfoHeaderContent = styled.div`
	margin-top: 5rem;
	text-align: center;

	padding: 1rem 2rem;
`

const HeaderTitle = styled.span`
	width: 100%;
	font-size: 3rem;
	font-weight: 700;
	color: #ffffff;
	text-shadow: 1px 1px gray;
`

const SubHeaderTitle = styled.span`
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 400;
	color: #ffffff;
	display: block;
	text-shadow: 1px 1px gray;
`

const ContentTitle = styled.span`
	width: 100%;
	text-decoration: underline;
	font-size: 2.4rem;
	font-weight: 700;
	text-shadow: 1px 1px gray;
`

const ContentTitleWhite = styled.span`
	color: #ffffff;
	width: 100%;
	text-decoration: underline;
	font-size: 2.4rem;
	font-weight: 700;
	text-shadow: 1px 1px gray;
`

const ArchivingWrapper = styled.div`
	background-color: black;
`

const ProjectWrapper = styled.div`
	background-color: #9be4a5;
`

const CareerWrapper = styled.div`
	background-color: #eee7e7;
	min-height: 1000px;
`

const SkillCard = styled(Card)`
	width: 18rem;
	box-shadow: 1rem 1rem 1rem 0 rgba(68, 68, 68, 0.2);
	border-radius: 1rem;
`

const ArchiveCard = styled(Card)`
	width: 25rem;
	height: 18rem;
	box-shadow: 1rem 1rem 1rem 0 rgba(68, 68, 68, 0.2);
	border-radius: 1rem;
	font-size: 1.2rem;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&.animationLeft {
		animation-name: opacityLeft;
		animation-duration: 1500ms;

		@keyframes opacityLeft {
			from {
				opacity: 0;
				transform: translate3d(-100%, 0, 0);
			}
			to {
				opacity: 1;
				transform: translateZ(0);
			}
		}
	}

	&.animationRight {
		animation-name: opacityRight;
		animation-duration: 1500ms;

		@keyframes opacityRight {
			from {
				opacity: 0;
				transform: translate3d(100%, 0, 0);
			}
			to {
				opacity: 1;
				transform: translateZ(0);
			}
		}
	}

	&.animationHidden {
		visibility: hidden;
	}
`

const SkillImages = styled.img`
	width: 15rem;
`

const ArchiveImages = styled.img`
	width: 15rem;
	padding: 1rem;
`

const ArchiveUl = styled.ul`
	padding-left: 1.2rem;
`

const ArchiveLi = styled.li``

export const PortFolio = () => {
	const dispatch = useDispatch()
	const menuStatus = useSelector((state: RootState) => state.menuSlice)
	const element = useRef<HTMLDivElement>(null)
	const skillElement = useRef<HTMLDivElement>(null)
	const archiveElement = useRef<HTMLDivElement>(null)
	const projectElement = useRef<HTMLDivElement>(null)
	const careerElement = useRef<HTMLDivElement>(null)
	const skillsElement = useRef<HTMLDivElement>(null)
	const githubElement = useRef<HTMLDivElement>(null)
	const tistoryElement = useRef<HTMLDivElement>(null)
	const isInViewport = useIntersectionObsever(skillsElement)
	const gitHubIsInViewport = useIntersectionObsever(githubElement)
	const tistoryIsInViewport = useIntersectionObsever(tistoryElement)

	useEffect(() => {}, [gitHubIsInViewport])

	useLayoutEffect(() => {
		if (menuStatus.menuStatus == 'Top') {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			})
			dispatch(menuReset())
		}

		if (menuStatus.menuStatus == 'About') {
			const topHeight = element.current?.offsetTop
			window.scrollTo({
				top: topHeight! - 80,
				behavior: 'smooth',
			})
			dispatch(menuReset())
		}

		if (menuStatus.menuStatus == 'Skills') {
			const topHeight = skillElement.current?.offsetTop
			window.scrollTo({
				top: topHeight! - 80,
				behavior: 'smooth',
			})
			dispatch(menuReset())
		}

		if (menuStatus.menuStatus == 'Archiving') {
			const topHeight = archiveElement.current?.offsetTop
			window.scrollTo({
				top: topHeight! - 80,
				behavior: 'smooth',
			})
			dispatch(menuReset())
		}

		if (menuStatus.menuStatus == 'Projects') {
			const topHeight = projectElement.current?.offsetTop
			window.scrollTo({
				top: topHeight! - 80,
				behavior: 'smooth',
			})
			dispatch(menuReset())
		}

		if (menuStatus.menuStatus == 'Career') {
			const topHeight = careerElement.current?.offsetTop
			window.scrollTo({
				top: topHeight! - 80,
				behavior: 'smooth',
			})
			dispatch(menuReset())
		}
	}, [menuStatus])

	return (
		<InfoWrapper>
			<InfoHeaderWrapper>
				<InfoHeader>
					<HeaderTitle>FRONTEND DEVELOPER</HeaderTitle>
					<HeaderTitle>신 진 섭</HeaderTitle>
				</InfoHeader>
				<InfoHeaderContent>
					<SubHeaderTitle>안녕하세요.</SubHeaderTitle>
					<SubHeaderTitle>4년차 프론트엔드 개발자입니다.</SubHeaderTitle>
					<SubHeaderTitle>
						좋은 동료 분들과 함께 할 기회가 많았으면 좋겠습니다.
					</SubHeaderTitle>
				</InfoHeaderContent>
			</InfoHeaderWrapper>
			<ContentWrapper ref={element}>
				<InfoContent>
					<ContentTitle>
						<SmileOutlined style={{ marginRight: '0.5rem' }} />
						About Me
					</ContentTitle>
				</InfoContent>
				<ContentFlexWrapper>
					<InfoAboutBox>
						<InnerFlexDiv>
							<UserOutlined />
							<BlockDiv>
								<InnerHeaderDiv>이름</InnerHeaderDiv>
								<InnerContentDiv>신진섭</InnerContentDiv>
							</BlockDiv>
						</InnerFlexDiv>
					</InfoAboutBox>
					<InfoAboutBox>
						<InnerFlexDiv>
							<CalendarOutlined />
							<BlockDiv>
								<InnerHeaderDiv>생년월일</InnerHeaderDiv>
								<InnerContentDiv>93.03.22</InnerContentDiv>
							</BlockDiv>
						</InnerFlexDiv>
					</InfoAboutBox>
					<InfoAboutBox>
						<InnerFlexDiv>
							<EnvironmentOutlined />
							<BlockDiv>
								<InnerHeaderDiv>주소지</InnerHeaderDiv>
								<InnerContentDiv>성남시 분당구</InnerContentDiv>
							</BlockDiv>
						</InnerFlexDiv>
					</InfoAboutBox>
					<InfoAboutBox>
						<InnerFlexDiv>
							<PhoneOutlined />
							<BlockDiv>
								<InnerHeaderDiv>연락처</InnerHeaderDiv>
								<InnerContentDiv>010-4738-3124</InnerContentDiv>
							</BlockDiv>
						</InnerFlexDiv>
					</InfoAboutBox>
					<InfoAboutBox>
						<InnerFlexDiv>
							<MailOutlined />
							<BlockDiv>
								<InnerHeaderDiv>이메일</InnerHeaderDiv>
								<InnerContentDiv>hello2323@naver.com</InnerContentDiv>
							</BlockDiv>
						</InnerFlexDiv>
					</InfoAboutBox>
					<InfoAboutBox>
						<InnerFlexDiv>
							<BankOutlined />
							<BlockDiv>
								<InnerHeaderDiv>학력</InnerHeaderDiv>
								<InnerContentDiv>국가평생교육원</InnerContentDiv>
								<InnerContentDiv>(컴퓨터공학과)</InnerContentDiv>
							</BlockDiv>
						</InnerFlexDiv>
					</InfoAboutBox>
				</ContentFlexWrapper>
			</ContentWrapper>

			<SkillWrapper ref={skillElement}>
				<InfoContent>
					<ContentTitle>
						<StarOutlined style={{ marginRight: '0.5rem' }} />
						Skills
					</ContentTitle>
				</InfoContent>
				<ContentWrapper ref={skillsElement}>
					<SkillFlexWrapper>
						<SkillCard bordered hoverable title="Frontend">
							<SkillImages src={HtmlIcon}></SkillImages>
							<SkillImages src={TsIcon}></SkillImages>
							<SkillImages src={ReactIcon}></SkillImages>
							<SkillImages src={ReduxIcon}></SkillImages>
							<SkillImages src={StyledIcon}></SkillImages>
							<SkillImages src={AntdIcon}></SkillImages>
						</SkillCard>
						<SkillCard bordered hoverable title="Backend">
							<SkillImages src={CIcon}></SkillImages>
							<SkillImages src={JavaIcon}></SkillImages>
						</SkillCard>
						<SkillCard bordered hoverable title="Configuration Management">
							<SkillImages src={GitHubIcon}></SkillImages>
							<SkillImages src={GitlabIcon}></SkillImages>
						</SkillCard>
						<SkillCard bordered hoverable title="Communication">
							<SkillImages src={JiraIcon}></SkillImages>
							<SkillImages src={ZeplinIcon}></SkillImages>
							<SkillImages src={FigmaIcon}></SkillImages>
						</SkillCard>
						<SkillCard bordered hoverable title="Deployment">
							<SkillImages src={AwsIcon}></SkillImages>
							<SkillImages src={DockerIcon}></SkillImages>
						</SkillCard>
						<SkillCard bordered hoverable title="DataBase">
							<SkillImages src={MysqlIcon}></SkillImages>
							<SkillImages src={OracleIcon}></SkillImages>
						</SkillCard>
					</SkillFlexWrapper>
				</ContentWrapper>
			</SkillWrapper>
			<ArchivingWrapper ref={archiveElement}>
				<InfoContent>
					<ContentTitleWhite>
						<PaperClipOutlined style={{ marginRight: '0.5rem' }} />
						Archiving
					</ContentTitleWhite>
				</InfoContent>
				<ContentWrapper>
					<ArchiveFlexWrapper>
						<ArchiveCard
							bordered
							hoverable
							title={<ArchiveImages src={GithubMiniIcon}></ArchiveImages>}
							onClick={() => open('https://github.com/JinSub-SHIN', '_blank')}
							ref={githubElement}
							className={
								gitHubIsInViewport ? 'animationLeft' : 'animationHidden'
							}
						>
							<strong>소스 코드 저장소 입니다</strong>
							<ArchiveUl>
								<ArchiveLi>과거 프로젝트 소스 코드</ArchiveLi>
								<ArchiveLi>알고리즘 공부 소스 코드</ArchiveLi>
								<ArchiveLi>기타 개인 공부 소스 코드</ArchiveLi>
							</ArchiveUl>
						</ArchiveCard>
						<ArchiveCard
							bordered
							hoverable
							title={<ArchiveImages src={TistoryMiniIcon}></ArchiveImages>}
							onClick={() =>
								open('https://just-take-the-first-step.tistory.com/', '_blank')
							}
							ref={tistoryElement}
							className={
								tistoryIsInViewport ? 'animationRight' : 'animationHidden'
							}
						>
							<strong>공부 및 지식 공유 기술 블로그 입니다</strong>
							<ArchiveUl>
								<ArchiveLi>공부한 것을 리마인드 용도</ArchiveLi>
								<ArchiveLi>웹 관련 지식 정리</ArchiveLi>
								<ArchiveLi>지식 공유를 통한 선한 영향력 행사</ArchiveLi>
							</ArchiveUl>
						</ArchiveCard>
					</ArchiveFlexWrapper>
				</ContentWrapper>
			</ArchivingWrapper>
			<ProjectWrapper ref={projectElement}>
				<InfoContent>
					<ContentTitle>
						<TrophyOutlined style={{ marginRight: '0.5rem' }} />
						Projects
					</ContentTitle>
				</InfoContent>
				<ContentWrapper>
					<ProjectList />
				</ContentWrapper>
			</ProjectWrapper>
			<CareerWrapper ref={careerElement}>
				<InfoContent>
					<ContentTitle>
						<LaptopOutlined style={{ marginRight: '0.5rem' }} />
						Career
					</ContentTitle>
				</InfoContent>
				<ContentWrapper>
					<CareerTimeLine />
				</ContentWrapper>
			</CareerWrapper>
		</InfoWrapper>
	)
}
