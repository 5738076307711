export const TopBackGround = require('./photo/main_pc.jpg')
export const WattManagerMain = require('./photo/wattManager_main.png')
export const WattManagerHeader = require('./photo/watt_main_header.png')
export const WattManagerAcessApplyList = require('./photo/watt_acess_apply_list.png')
export const WattManagerAcessApply = require('./photo/watt_acess_apply.png')
export const WattManagerJobAcessPrint = require('./photo/watt_job_acess_print.png')
export const HanilMain = require('./photo/hanil_main.png')
export const HanilEntrance = require('./photo/hanil_entrance.png')
export const HanilEntranceList = require('./photo/hanil_entrance_list.png')
export const HanilMainMobile = require('./photo/hanil_main_mobile.png')
export const HanilEntranceMobile = require('./photo/hanil_entrance_mobile.png')
export const HanilEntranceListMobile = require('./photo/hanil_entrance_list_mobile.png')
export const KorailAdminMain = require('./photo/korail_safety_main.png')
export const KorailAdminFirst = require('./photo/korail_safety.png')
export const KorailAdminSecond = require('./photo/korail_safety_second.png')
export const KorailAdminThird = require('./photo/korail_safety_third.png')
export const HealthMain = require('./photo/health_main.png')
export const HealthLogic = require('./photo/health_call.png')
export const KoreaMain = require('./photo/korea_main.png')
export const KoreaDetail = require('./photo/korea_detail.png')
export const KoreaCheckWord = require('./photo/korea_check_word.png')
export const JobitMain = require('./photo/jobit_main.png')
export const JobitResume = require('./photo/jobit_resume.png')
export const JobitNotice = require('./photo/jobit_notice.png')
export const HtmlIcon = require('./icon/html_icon.png')
export const TsIcon = require('./icon/ts_icon.png')
export const ReactIcon = require('./icon/react_icon.png')
export const ReduxIcon = require('./icon/redux_icon.png')
export const StyledIcon = require('./icon/styled_icon.png')
export const AntdIcon = require('./icon/antd_icon.png')
export const CIcon = require('./icon/C_icon.jpg')
export const JavaIcon = require('./icon/java_icon.png')
export const GitlabIcon = require('./icon/gitlab_icon.png')
export const GitHubIcon = require('./icon/github_icon.png')
export const FigmaIcon = require('./icon/figma_icon.png')
export const ZeplinIcon = require('./icon/zeplin_icon.png')
export const JiraIcon = require('./icon/jira_icon.png')
export const DockerIcon = require('./icon/docker_icon.png')
export const AwsIcon = require('./icon/aws_icon.png')
export const MysqlIcon = require('./icon/mysql_icon.png')
export const OracleIcon = require('./icon/oracle_icon.png')
export const GithubMiniIcon = require('./icon/github_mini_icon.png')
export const TistoryMiniIcon = require('./icon/tistory_mini_icon.png')
export const PortfolioMobile = require('./photo/portfolio_moblie.png')
export const PortfolioPC = require('./photo/portfolio_pc.png')
export const GloryMapleMain = require('./photo/glorymaple_main.png')
export const GloryMapleMecoin = require('./photo/mecoin_page.png')
