import { HomeOutlined, LinkOutlined } from '@ant-design/icons'
import { Timeline } from 'antd'
import { styled } from 'styled-components'

const HeaderSpan = styled.span`
	font-size: 1.6rem;
	font-weight: 800;
	padding-right: 1rem;
`

const LinkSpan = styled.span`
	font-size: 1.1rem;
	font-weight: 500;
	padding-right: 1rem;
	cursor: pointer;
`

const DoNotAcessSpan = styled.span`
	font-size: 1.1rem;
	font-weight: 500;
	padding-right: 1rem;
	cursor: no-drop;
`

const PositionSpan = styled.span`
	font-size: 1rem;
	font-weight: 300;
	color: gray;
`

const ContentSpan = styled.span`
	font-size: 1.1rem;
	font-weight: 400;
`

const TimeLineHeader = styled.div`
	margin-bottom: 20px;
`

const TimeLineUl = styled.ul`
	padding-left: 1.2rem;
`

const TimeLineLi = styled.li``

export const CareerTimeLine = () => {
	const handleMove = (params: string) => {
		if (params == 'watt') {
			open('https://wattsolution.co.kr/', '_blank')
		}

		if (params == 'kosta') {
			open('https://www.kosta.or.kr/', '_blank')
		}
	}

	return (
		<Timeline
			items={[
				{
					children: (
						<>
							<TimeLineHeader>
								<div>
									<span>구직중</span>
								</div>
								<div>
									<HeaderSpan>집</HeaderSpan>
									<PositionSpan>Unemployed Engineer</PositionSpan>
								</div>
							</TimeLineHeader>
						</>
					),
				},
				{
					color: 'gray',
					children: (
						<>
							<TimeLineHeader>
								<div>
									<span>2022.06 ~ 2023.09</span>
								</div>
								<div>
									<HeaderSpan>(주)WATT</HeaderSpan>
									<LinkSpan onClick={() => handleMove('watt')}>
										<LinkOutlined /> 링크
									</LinkSpan>
									<PositionSpan>Frontend Engineer</PositionSpan>
								</div>
							</TimeLineHeader>
							<Timeline
								items={[
									{
										color: 'green',
										children: (
											<>
												<div>
													<ContentSpan>
														전반적인 관리 및 기능 기획/개발
													</ContentSpan>
												</div>
												<TimeLineUl>
													<TimeLineLi>
														Vue 프로젝트를(레거시) React 로 마이그레이션하면서
														필요한 기술 기획
													</TimeLineLi>
													<TimeLineLi>
														사내 프로젝트에서 사용할 디자인 시스템 개발
													</TimeLineLi>
													<TimeLineLi>
														재사용화 컴포넌트 제작으로 개발 효율 증대
													</TimeLineLi>
													<TimeLineLi>
														한 서버에 여러 노드버전 구동을 위한 React/Nginx 를
														함께 도커라이징 배포
													</TimeLineLi>
													<TimeLineLi>
														웹/모바일 반응형 페이지 제작 경험
													</TimeLineLi>
													<TimeLineLi>
														다날 본인 인증 서비스 적용 경험
													</TimeLineLi>
													<TimeLineLi>출입 관련 웹 페이지 제작</TimeLineLi>
													<TimeLineLi>
														데이터 관리 목적 웹 페이지 제작
													</TimeLineLi>
													<TimeLineLi>관리자 전용 웹 페이지 제작</TimeLineLi>
												</TimeLineUl>
											</>
										),
									},
									{
										color: 'green',
										children: (
											<>
												<div>
													<ContentSpan>기타 업무</ContentSpan>
												</div>
												<TimeLineUl>
													<TimeLineLi>
														신입 개발자 사수 담당(코드리뷰, 피드백, 교육)
													</TimeLineLi>
													<TimeLineLi>Jira 대시보드 관리</TimeLineLi>
													<TimeLineLi>기획 문서 작업</TimeLineLi>
													<TimeLineLi>개발자 인수인계 문서 작성</TimeLineLi>
												</TimeLineUl>
											</>
										),
									},
								]}
							/>
						</>
					),
				},
				{
					color: 'gray',
					children: (
						<>
							<TimeLineHeader>
								<div>
									<span>2020.07 ~ 2022.03</span>
								</div>
								<div>
									<HeaderSpan>네오젠소프트</HeaderSpan>
									<DoNotAcessSpan>
										<LinkOutlined /> 없음
									</DoNotAcessSpan>
									<PositionSpan>FullStack Engineer</PositionSpan>
								</div>
							</TimeLineHeader>
							<Timeline
								items={[
									{
										color: 'green',
										children: (
											<>
												<div>
													<ContentSpan>
														프로젝트 총괄 개발 및 유지보수
													</ContentSpan>
												</div>
												<TimeLineUl>
													<TimeLineLi>
														사용자와 직접 소통하며, 기능 개발 및 유지보수
													</TimeLineLi>
													<TimeLineLi>
														DataBase 부터 Frontend 까지 풀스택 개발 경험
													</TimeLineLi>
													<TimeLineLi>
														기존 레거시 코드 리팩토링(개선) 경험
													</TimeLineLi>
													<TimeLineLi>Web App 반응형 개발 경험</TimeLineLi>
													<TimeLineLi>
														질병관리청 국가사업 프로젝트 웹 페이지 제작
													</TimeLineLi>
													<TimeLineLi>서울대병원 제증명 서비스 개발</TimeLineLi>
												</TimeLineUl>
											</>
										),
									},
									{
										color: 'green',
										children: (
											<>
												<div>
													<ContentSpan>기타 업무</ContentSpan>
												</div>
												<TimeLineUl>
													<TimeLineLi>
														기능 개발(변경) 관련 변경관리 문서 작성
													</TimeLineLi>
													<TimeLineLi>개발자 인수인계 문서 작성</TimeLineLi>
												</TimeLineUl>
											</>
										),
									},
								]}
							/>
						</>
					),
				},
				{
					color: 'gray',
					children: (
						<>
							<TimeLineHeader>
								<div>
									<span>2020.02 ~ 2020.07</span>
								</div>
								<div>
									<HeaderSpan>한국소프트웨어 기술진흥 협회</HeaderSpan>
									<LinkSpan onClick={() => handleMove('kosta')}>
										<LinkOutlined /> 링크
									</LinkSpan>
									<PositionSpan>Student</PositionSpan>
								</div>
							</TimeLineHeader>
							<Timeline
								items={[
									{
										color: 'green',
										children: (
											<>
												<div>
													<ContentSpan>수업 내용</ContentSpan>
												</div>
												<TimeLineUl>
													<TimeLineLi>
														Spring Framework 를 활용한 웹 페이지 구현
													</TimeLineLi>
													<TimeLineLi>Oracle 데이터베이스 사용</TimeLineLi>
													<TimeLineLi>JSP 로 웹 사이트 구현</TimeLineLi>
												</TimeLineUl>
											</>
										),
									},
									{
										color: 'green',
										children: (
											<>
												<div>
													<ContentSpan>모각코 및 스터디</ContentSpan>
												</div>
												<TimeLineUl>
													<TimeLineLi>
														수업이 끝난 후, 프로그래머스 알고리즘 관련 공부 및
														공유
													</TimeLineLi>
													<TimeLineLi>
														서로 이해하지 못하는 부분을 설명해주면서 리마인드
													</TimeLineLi>
													<TimeLineLi>
														수업 내용에 없던 React 를 별도 스터디
													</TimeLineLi>
												</TimeLineUl>
											</>
										),
									},
								]}
							/>
						</>
					),
				},
				{
					color: 'gray',
					children: <></>,
				},
			]}
		/>
	)
}
