import { MainPage } from 'pages/main/MainPage'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
export default function Router() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/*" element={<MainPage />} />
			</Routes>
		</BrowserRouter>
	)
}
