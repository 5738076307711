import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface MenuType {
	menuStatus: string
}

const initialState: MenuType = {
	menuStatus: '',
}

export const menuSlice = createSlice({
	name: 'menuSlice',
	initialState,
	reducers: {
		setMenuStatus: (state, action: PayloadAction<string>) => {
			state.menuStatus = action.payload
		},
		menuReset: () => initialState,
	},
})

export const { setMenuStatus, menuReset } = menuSlice.actions

export default menuSlice.reducer
