import { Card, Col, Image, Row } from 'antd'
import { styled } from 'styled-components'
import {
	GloryMapleMain,
	GloryMapleMecoin,
	HanilEntrance,
	HanilEntranceList,
	HanilEntranceListMobile,
	HanilEntranceMobile,
	HanilMain,
	HanilMainMobile,
	HealthLogic,
	HealthMain,
	JobitMain,
	JobitNotice,
	JobitResume,
	KorailAdminFirst,
	KorailAdminMain,
	KorailAdminSecond,
	KorailAdminThird,
	KoreaCheckWord,
	KoreaDetail,
	KoreaMain,
	PortfolioMobile,
	PortfolioPC,
	WattManagerAcessApply,
	WattManagerAcessApplyList,
	WattManagerHeader,
	WattManagerJobAcessPrint,
	WattManagerMain,
} from 'images'
import { CheckOutlined } from '@ant-design/icons'

const ProjectFlexWrapper = styled.div`
	justify-content: center;
`

const ProjectCard = styled(Card)`
	/* width: 50rem; */
	margin-bottom: 5rem;
	box-shadow: 1rem 1rem 1rem 0 rgba(68, 68, 68, 0.2);
	border-radius: 1rem;
`

const ProjectRow = styled(Row)`
	flex-flow: row;
`

const ProjectStackRow = styled(Row)`
	margin-bottom: 1rem;
	flex-flow: row;
`

const ProjectCol = styled(Col)`
	@media screen and (max-width: 760px) {
		display: none;
	}
	width: 300px;
`

const ProjectContent = styled.div`
	@media screen and (max-width: 760px) {
		padding-left: 0rem;
	}
	padding-left: 1rem;
`

const ProjectFlexHeader = styled.span`
	@media screen and (max-width: 760px) {
		display: none;
	}
	display: flex;
	justify-content: space-between;
`

const ProjectFlexHeaderMobile = styled.span`
	@media screen and (max-width: 760px) {
		display: flex;
	}
	display: none;
	justify-content: space-between;
`

const BlockSpan = styled.span`
	display: block;
`

const StackSpan = styled.span`
	font-weight: 800;
`

const RedSpan = styled.span`
	color: red;
	opacity: 0.9;
`

const SmallSpan = styled.span`
	font-size: 0.8rem;
`

const CustomImage = styled(Image)`
	@media screen and (max-width: 760px) {
		display: none;
	}
	width: 300px !important;
`

const MobileImage = styled(Image)``

const ImagePreviewWrapper = styled.div`
	@media screen and (max-width: 760px) {
		display: inline-block;
		width: 100%;
	}

	display: none;
`

export const ProjectList = () => {
	return (
		<ProjectFlexWrapper>
			<ProjectCard
				bordered
				hoverable
				title={
					<>
						<ProjectFlexHeader>
							<div>
								<strong>메이플 시뮬레이터</strong>
								<span>(1人 개인 프로젝트) </span>
								<SmallSpan>in Personal</SmallSpan>
							</div>
							<div>
								<SmallSpan>2024.07 ~ 진행중</SmallSpan>
							</div>
						</ProjectFlexHeader>
						<ProjectFlexHeaderMobile>
							<div>
								<strong>메이플 시뮬레이터</strong>
							</div>
						</ProjectFlexHeaderMobile>
					</>
				}
			>
				<ProjectRow>
					<ProjectCol>
						<Image.PreviewGroup>
							<CustomImage src={GloryMapleMain} />
						</Image.PreviewGroup>
						<Image.PreviewGroup>
							<CustomImage src={GloryMapleMecoin} />
						</Image.PreviewGroup>
					</ProjectCol>
					<Col flex="auto">
						<ImagePreviewWrapper>
							<Image.PreviewGroup>
								<MobileImage src={GloryMapleMain} />
								<MobileImage
									style={{ display: 'none' }}
									src={GloryMapleMecoin}
								/>
							</Image.PreviewGroup>
						</ImagePreviewWrapper>
						<ProjectContent>
							<strong>캐릭터 정보 조회 및 확률형 시뮬레이터 </strong>
							<span>플랫폼입니다.</span>
							<p></p>
							<BlockSpan>
								<RedSpan>넥슨</RedSpan>에서 제공해주는 API를 활용해 캐릭터 조회,
								랭킹, 큐브 사용 결과, 스타포스 사용 결과 등을 제공합니다.
							</BlockSpan>
							<p></p>
							<BlockSpan>
								이와 별개로, 메이플 내 주화 시세 변동 확인, 큐브 및 스타포스
								기대값 등을 경험 해 볼 수 있습니다.
							</BlockSpan>
							<p></p>
							<BlockSpan>
								넥슨 API 를 토대로 꾸준히 업데이트 예정이며, 메이플 컨텐츠
								업데이트 혹은 변경으로 생기는 사항을 빠르게 업데이트하여 운영 해
								볼 예정입니다.
							</BlockSpan>
							<p></p>
							<hr />

							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Frontend
									</StackSpan>
								</Col>
								<Col flex="auto">
									React, Typescript, styled-components, ApexChart
								</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										State Management
									</StackSpan>
								</Col>
								<Col flex="auto">Redux-toolkit</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Deployment
									</StackSpan>
								</Col>
								<Col flex="auto">Docker</Col>
							</ProjectStackRow>
						</ProjectContent>
					</Col>
				</ProjectRow>
			</ProjectCard>
			<ProjectCard
				bordered
				hoverable
				title={
					<>
						<ProjectFlexHeader>
							<div>
								<strong>포트폴리오</strong>
								<span>(1人 개인 프로젝트) </span>
								<SmallSpan>in Personal</SmallSpan>
							</div>
							<div>
								<SmallSpan>2023.11 ~ 2024.01</SmallSpan>
							</div>
						</ProjectFlexHeader>
						<ProjectFlexHeaderMobile>
							<div>
								<strong>포트폴리오</strong>
							</div>
						</ProjectFlexHeaderMobile>
					</>
				}
			>
				<ProjectRow>
					<ProjectCol>
						<Image.PreviewGroup>
							<CustomImage src={PortfolioPC} />
						</Image.PreviewGroup>
					</ProjectCol>
					<Col flex="auto">
						<ImagePreviewWrapper>
							<Image.PreviewGroup>
								<MobileImage src={PortfolioPC} />
							</Image.PreviewGroup>
						</ImagePreviewWrapper>
						<ProjectContent>
							<strong>웹 형식 이력서</strong>
							<span>로써 쉽게 커리어를 확인 할 수 있는 플랫폼입니다.</span>
							<p></p>
							<BlockSpan>
								<RedSpan>PC 및 모바일 반응형</RedSpan>으로 제작되었습니다.
							</BlockSpan>
							<p></p>
							<BlockSpan>AWS 에 배포되어, 운영되고 있습니다.</BlockSpan>
							<p></p>
							<hr />

							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Frontend
									</StackSpan>
								</Col>
								<Col flex="auto">
									React, Typescript, Ant-Design, styled-components
								</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										State Management
									</StackSpan>
								</Col>
								<Col flex="auto">Redux-toolkit</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Deployment
									</StackSpan>
								</Col>
								<Col flex="auto">Docker</Col>
							</ProjectStackRow>
						</ProjectContent>
					</Col>
				</ProjectRow>
			</ProjectCard>
			<ProjectCard
				bordered
				hoverable
				title={
					<>
						<ProjectFlexHeader>
							<div>
								<strong>와트매니저</strong>
								<span>(4人 팀 프로젝트) </span>
								<SmallSpan>in Watt</SmallSpan>
							</div>
							<div>
								<SmallSpan>2023.03 ~ 2023.09</SmallSpan>
							</div>
						</ProjectFlexHeader>
						<ProjectFlexHeaderMobile>
							<div>
								<strong>와트매니저</strong>
							</div>
						</ProjectFlexHeaderMobile>
					</>
				}
			>
				<ProjectRow>
					<ProjectCol>
						<Image.PreviewGroup>
							<CustomImage src={WattManagerMain} />
							<CustomImage
								style={{ display: 'none' }}
								src={WattManagerHeader}
							/>
							<CustomImage
								style={{ display: 'none' }}
								src={WattManagerAcessApplyList}
							/>
							<CustomImage
								style={{ display: 'none' }}
								src={WattManagerAcessApply}
							/>
							<CustomImage
								style={{ display: 'none' }}
								src={WattManagerJobAcessPrint}
							/>
						</Image.PreviewGroup>
					</ProjectCol>
					<Col flex="auto">
						<ImagePreviewWrapper>
							<Image.PreviewGroup>
								<MobileImage src={WattManagerMain} />
								<MobileImage
									src={WattManagerHeader}
									style={{ display: 'none' }}
								/>
								<MobileImage
									src={WattManagerAcessApplyList}
									style={{ display: 'none' }}
								/>
								<MobileImage
									src={WattManagerAcessApply}
									style={{ display: 'none' }}
								/>
								<MobileImage
									src={WattManagerJobAcessPrint}
									style={{ display: 'none' }}
								/>
							</Image.PreviewGroup>
						</ImagePreviewWrapper>
						<ProjectContent>
							<strong>
								산업 현장 모니터링 및 스마트팩토리 출입 신청 관련 업무
							</strong>
							<span>
								관련 웹로써 모니터링 된 데이터를 확인 및 출입 관련 업무를 수행
								할 수 있는 플랫폼입니다.
							</span>
							<p></p>
							<BlockSpan>
								해당 프로젝트에서 사용 할 디자인 시스템을{' '}
								<RedSpan>Ant Design CSS</RedSpan>와{' '}
								<RedSpan>styled-components</RedSpan> 를 활용해 개발 및
								적용하였습니다.
							</BlockSpan>
							<p></p>
							<BlockSpan>
								<RedSpan>Docker</RedSpan> 로 한 서버의 여러 노드 버전을 구동하기
								위해 <RedSpan>React+Ngnix 도커라이징</RedSpan>을 진행하여,
								서버에 웹 서비스를 올려보는 경험을 하였습니다.{' '}
							</BlockSpan>
							<p></p>
							<BlockSpan>
								단순 CRUD 기능이 반복 되는 페이지 구성이 많아, 공통 컴포넌트를
								제작하여, 협업 자의 개발시간을 단축 시켰습니다.
							</BlockSpan>
							<p></p>
							<hr />

							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Frontend
									</StackSpan>
								</Col>
								<Col flex="auto">
									React, Typescript, Ant-Design, styled-components
								</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Backend
									</StackSpan>
								</Col>
								<Col flex="auto">Java, Spring</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										DataBase
									</StackSpan>
								</Col>
								<Col flex="auto">MySQL</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										State Management
									</StackSpan>
								</Col>
								<Col flex="auto">Redux-toolkit</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Deployment
									</StackSpan>
								</Col>
								<Col flex="auto">Docker</Col>
							</ProjectStackRow>
						</ProjectContent>
					</Col>
				</ProjectRow>
			</ProjectCard>
			<ProjectCard
				bordered
				hoverable
				title={
					<>
						<ProjectFlexHeader>
							<div>
								<strong>한일 스마트 팩토리 구축</strong>
								<span>(4人 팀 프로젝트) </span>
								<SmallSpan>in Watt</SmallSpan>
							</div>
							<div>
								<SmallSpan>2022.09 ~ 2023.05</SmallSpan>
							</div>
						</ProjectFlexHeader>
						<ProjectFlexHeaderMobile>
							<div>
								<strong>한일 스마트 팩토리 구축</strong>
							</div>
						</ProjectFlexHeaderMobile>
					</>
				}
			>
				<ProjectRow>
					<ProjectCol>
						<Image.PreviewGroup>
							<CustomImage src={HanilMain} />
							<CustomImage style={{ display: 'none' }} src={HanilEntrance} />
							<CustomImage
								style={{ display: 'none' }}
								src={HanilEntranceList}
							/>
						</Image.PreviewGroup>
						<p></p>
						<Image.PreviewGroup>
							<CustomImage height={700} src={HanilMainMobile} />
							<CustomImage
								style={{ display: 'none' }}
								src={HanilEntranceMobile}
							/>
							<CustomImage
								style={{ display: 'none' }}
								src={HanilEntranceListMobile}
							/>
						</Image.PreviewGroup>
					</ProjectCol>
					<Col flex="auto">
						<ImagePreviewWrapper>
							<Image.PreviewGroup>
								<MobileImage src={HanilMain} />
								<MobileImage style={{ display: 'none' }} src={HanilEntrance} />
								<MobileImage
									style={{ display: 'none' }}
									src={HanilEntranceList}
								/>
							</Image.PreviewGroup>
							<p></p>
							<Image.PreviewGroup>
								<MobileImage src={HanilMainMobile} />
								<MobileImage
									style={{ display: 'none' }}
									src={HanilEntranceMobile}
								/>
								<MobileImage
									style={{ display: 'none' }}
									src={HanilEntranceListMobile}
								/>
							</Image.PreviewGroup>
						</ImagePreviewWrapper>
						<ProjectContent>
							<strong>한일 시멘트 현장 출입 신청 업무 등록</strong>
							<span>
								관련 웹으로써 일반, 공사, 협력업체, 납품업체 등 업체 출입 신청이
								가능한 어플리케이션 입니다.
							</span>
							<p></p>
							<BlockSpan>
								해당 프로젝트에서 사용 할 디자인 시스템을{' '}
								<RedSpan>Ant Design CSS</RedSpan>와{' '}
								<RedSpan>styled-components</RedSpan> 를 활용해 개발 및
								적용하였습니다.
							</BlockSpan>
							<p></p>
							<BlockSpan>
								<RedSpan>Docker</RedSpan> 로 한 서버의 여러 노드 버전을 구동하기
								위해 <RedSpan>React+Ngnix 도커라이징</RedSpan>을 진행하여,
								서버에 웹 서비스를 올려보는 경험을 하였습니다.{' '}
							</BlockSpan>
							<p></p>
							<BlockSpan>
								<RedSpan>React-device-detect</RedSpan> 를 통해 웹, 모바일 환경
								반응형 서비스를 구현하였습니다.
							</BlockSpan>
							<p></p>
							<BlockSpan>
								<RedSpan>다날 본인 인증 서비스</RedSpan>를 처음 도입해 봄으로써,
								해당 서비스 적용시 개인 정보 제공 등 사용자에게 필수로 알려야 할
								정보들을 알게 되었습니다.
							</BlockSpan>
							<p></p>
							<hr />

							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Frontend
									</StackSpan>
								</Col>
								<Col flex="auto">
									React, Typescript, Ant-Design, styled-components
								</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Backend
									</StackSpan>
								</Col>
								<Col flex="auto">Java, Spring</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										DataBase
									</StackSpan>
								</Col>
								<Col flex="auto">MySQL</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										State Management
									</StackSpan>
								</Col>
								<Col flex="auto">Redux-toolkit</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Deployment
									</StackSpan>
								</Col>
								<Col flex="auto">Docker</Col>
							</ProjectStackRow>
						</ProjectContent>
					</Col>
				</ProjectRow>
			</ProjectCard>
			<ProjectCard
				bordered
				hoverable
				title={
					<>
						<ProjectFlexHeader>
							<div>
								<strong>철도 공사 안전점검</strong>
								<span>(2人 팀 프로젝트) </span>
								<SmallSpan>in Watt</SmallSpan>
							</div>
							<div>
								<SmallSpan>2022.07 ~ 2022.08</SmallSpan>
							</div>
						</ProjectFlexHeader>
						<ProjectFlexHeaderMobile>
							<div>
								<strong>철도 공사 안전점검</strong>
							</div>
						</ProjectFlexHeaderMobile>
					</>
				}
			>
				<ProjectRow>
					<ProjectCol>
						<Image.PreviewGroup>
							<CustomImage src={KorailAdminMain} />
							<CustomImage style={{ display: 'none' }} src={KorailAdminFirst} />
							<CustomImage
								style={{ display: 'none' }}
								src={KorailAdminSecond}
							/>
							<CustomImage style={{ display: 'none' }} src={KorailAdminThird} />
						</Image.PreviewGroup>
					</ProjectCol>
					<Col flex="auto">
						<ImagePreviewWrapper>
							<Image.PreviewGroup>
								<MobileImage src={KorailAdminMain} />
								<MobileImage
									style={{ display: 'none' }}
									src={KorailAdminFirst}
								/>
								<MobileImage
									style={{ display: 'none' }}
									src={KorailAdminSecond}
								/>
								<MobileImage
									style={{ display: 'none' }}
									src={KorailAdminThird}
								/>
							</Image.PreviewGroup>
						</ImagePreviewWrapper>
						<ProjectContent>
							<strong>철도 공사 안전점검 관리자 페이지</strong>
							<span>
								로써 철도 관련 안전점검을 진행 할 때 부재명 등을 관리하는
								페이지입니다.
							</span>
							<p></p>
							<BlockSpan>
								기존 레거시가 <RedSpan>Nuxt</RedSpan> 로 되어 있어,{' '}
								<RedSpan>Nuxt</RedSpan> 위에 신규 기능을 추가하였습니다. Nuxt 를
								처음 써본 저에게는 <RedSpan>Vue</RedSpan> 구조에 대해 알게 되는
								계기가 되었습니다.
							</BlockSpan>
							<p></p>
							<BlockSpan>
								<RedSpan>Vuex</RedSpan> 를 통해 상태 관리를 진행하였습니다.
							</BlockSpan>
							<p></p>
							<hr />

							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Frontend
									</StackSpan>
								</Col>
								<Col flex="auto">Nuxt(Vue), JavaScript, SCSS</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Backend
									</StackSpan>
								</Col>
								<Col flex="auto">Java, Spring</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										DataBase
									</StackSpan>
								</Col>
								<Col flex="auto">MySQL</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										State Management
									</StackSpan>
								</Col>
								<Col flex="auto">Vuex</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Deployment
									</StackSpan>
								</Col>
								<Col flex="auto">Build 결과물 직접 배포</Col>
							</ProjectStackRow>
						</ProjectContent>
					</Col>
				</ProjectRow>
			</ProjectCard>
			<ProjectCard
				bordered
				hoverable
				title={
					<>
						<ProjectFlexHeader>
							<div>
								<strong>서울대병원 제증명 서비스</strong>
								<span>(1人 프로젝트) </span>
								<SmallSpan>in 네오젠소프트</SmallSpan>
							</div>
							<div>
								<SmallSpan>2021.08 ~ 2022.03</SmallSpan>
							</div>
						</ProjectFlexHeader>
						<ProjectFlexHeaderMobile>
							<div>
								<strong>서울대병원 제증명 서비스</strong>
							</div>
						</ProjectFlexHeaderMobile>
					</>
				}
			>
				<ProjectRow>
					<ProjectCol>
						<Image.PreviewGroup>
							<CustomImage src={HealthMain} />
							<CustomImage style={{ display: 'none' }} src={HealthLogic} />
						</Image.PreviewGroup>
					</ProjectCol>
					<Col flex="auto">
						<ImagePreviewWrapper>
							<Image.PreviewGroup>
								<MobileImage src={HealthMain} />
								<MobileImage style={{ display: 'none' }} src={HealthLogic} />
							</Image.PreviewGroup>
						</ImagePreviewWrapper>
						<ProjectContent>
							<strong>
								서울대병원(분당) 에서 진료시 제증명 정보를 확인하는 어플리케이션
							</strong>
							<span>
								로써 사용자가 병원에 가지 않아도, 어플리케이션으로 제증명 정보를
								확인 할 수 있습니다.
							</span>
							<p></p>
							<BlockSpan>
								<RedSpan>WebApp</RedSpan> 기반으로써, 반응형 서비스를
								제작하였습니다.
							</BlockSpan>
							<p></p>
							<BlockSpan>
								<RedSpan>프로시저</RedSpan>를 작성하여, 병원 측 DB 에 접근하는
								DB 링크를 통해 접근하는 경험을 하였습니다.
							</BlockSpan>
							<p></p>
							<BlockSpan>
								<RedSpan>풀스택</RedSpan> 개발로써, 백앤드와 프론트를 모두 개발
								하였습니다.
							</BlockSpan>
							<p></p>
							<hr />
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Frontend
									</StackSpan>
								</Col>
								<Col flex="auto">Javascript, jQuery</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Backend
									</StackSpan>
								</Col>
								<Col flex="auto">C# ASP.NET, PL/SQL </Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										DataBase
									</StackSpan>
								</Col>
								<Col flex="auto">Oracle</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										State Management
									</StackSpan>
								</Col>
								<Col flex="auto">Web Storage</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Deployment
									</StackSpan>
								</Col>
								<Col flex="auto">Build 결과물 직접 배포</Col>
							</ProjectStackRow>
						</ProjectContent>
					</Col>
				</ProjectRow>
			</ProjectCard>
			<ProjectCard
				bordered
				hoverable
				title={
					<>
						<ProjectFlexHeader>
							<div>
								<strong>국가건강정보 포털 제공 체계 구축</strong>
								<span>(1人 개인 프로젝트) </span>
								<SmallSpan>in 네오젠소프트</SmallSpan>
							</div>
							<div>
								<SmallSpan>2020.08 ~ 2021.12</SmallSpan>
							</div>
						</ProjectFlexHeader>
						<ProjectFlexHeaderMobile>
							<div>
								<strong>국가건강정보 포털 제공 체계 구축</strong>
							</div>
						</ProjectFlexHeaderMobile>
					</>
				}
			>
				<ProjectRow>
					<ProjectCol>
						<Image.PreviewGroup>
							<CustomImage src={KoreaMain} />
							<CustomImage style={{ display: 'none' }} src={KoreaDetail} />
							<CustomImage style={{ display: 'none' }} src={KoreaCheckWord} />
						</Image.PreviewGroup>
					</ProjectCol>
					<Col flex="auto">
						<ImagePreviewWrapper>
							<Image.PreviewGroup>
								<MobileImage src={KoreaMain} />
								<MobileImage style={{ display: 'none' }} src={KoreaDetail} />
								<MobileImage style={{ display: 'none' }} src={KoreaCheckWord} />
							</Image.PreviewGroup>
						</ImagePreviewWrapper>
						<ProjectContent>
							<strong>질병관리청에서 사용한 국가 프로젝트</strong>
							<span>
								로써 병원 측 전문의들이 의료 용어에 대한 어려움을 해소하고자,
								용어 해설 등을 해당 프로젝트에 담고 그 데이터를 질병관리청
								국가건강정보포털로 이관하여 게시를 위한 프로젝트입니다.
							</span>
							<p></p>
							<BlockSpan>
								사용자와 직접 소통하며, 불편한 점, 개선사항 등을 적극 협의하면서
								해결한 프로젝트로써 기획부터 개발까지 경험한 값진
								프로젝트입니다.
							</BlockSpan>
							<p></p>
							<BlockSpan>
								<RedSpan>풀스택</RedSpan> 개발로써, 백앤드와 프론트를 모두 개발
								하였습니다.
							</BlockSpan>
							<p></p>
							<hr />
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Frontend
									</StackSpan>
								</Col>
								<Col flex="auto">Javascript, jQuery</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Backend
									</StackSpan>
								</Col>
								<Col flex="auto">C# ASP.NET, PL/SQL </Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										DataBase
									</StackSpan>
								</Col>
								<Col flex="auto">Oracle</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										State Management
									</StackSpan>
								</Col>
								<Col flex="auto">Web Storage</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Deployment
									</StackSpan>
								</Col>
								<Col flex="auto">Build 결과물 직접 배포</Col>
							</ProjectStackRow>
						</ProjectContent>
					</Col>
				</ProjectRow>
			</ProjectCard>
			<ProjectCard
				bordered
				hoverable
				title={
					<>
						<ProjectFlexHeader>
							<div>
								<strong>Job-IT</strong>
								<span>(6人 팀 프로젝트) </span>
								<SmallSpan>in Kosta</SmallSpan>
							</div>
							<div>
								<SmallSpan>2020.04 ~ 2020.07</SmallSpan>
							</div>
						</ProjectFlexHeader>
						<ProjectFlexHeaderMobile>
							<div>
								<strong>Job-IT</strong>
							</div>
						</ProjectFlexHeaderMobile>
					</>
				}
			>
				<ProjectRow>
					<ProjectCol>
						<Image.PreviewGroup>
							<CustomImage src={JobitMain} />
							<CustomImage style={{ display: 'none' }} src={JobitResume} />
							<CustomImage style={{ display: 'none' }} src={JobitNotice} />
						</Image.PreviewGroup>
					</ProjectCol>
					<Col flex="auto">
						<ImagePreviewWrapper>
							<Image.PreviewGroup>
								<MobileImage src={JobitMain} />
								<MobileImage style={{ display: 'none' }} src={JobitResume} />
								<MobileImage style={{ display: 'none' }} src={JobitNotice} />
							</Image.PreviewGroup>
						</ImagePreviewWrapper>
						<ProjectContent>
							<strong>구인/구직을 위한 사이트</strong>
							<span>
								로써 원티드나 사람인과 유사한 사이트입니다. 교육 과정을
								이수하면서 만난 친구들과 팀을 이루어 제작한 프로젝트입니다.
							</span>
							<p></p>
							<BlockSpan>
								<RedSpan>React</RedSpan>를 처음 접해보는 계기가 되었으며,
								프론트엔드를 총괄하면서, 일정 관리/업무 분배 등 리더쉽을 기를 수
								있는 기회가 되었습니다.
							</BlockSpan>
							<p></p>
							<BlockSpan>
								백엔드 개발자와 협업하는 과정에서 소통의 중요함을 깨닫게 되었고,
								필요한 지식도 함께 공유하며 학습 할 수 있었습니다.
							</BlockSpan>
							<p></p>
							<hr />
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Frontend
									</StackSpan>
								</Col>
								<Col flex="auto">React, Javascript</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										Backend
									</StackSpan>
								</Col>
								<Col flex="auto">Java, Spring</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										DataBase
									</StackSpan>
								</Col>
								<Col flex="auto">Oracle</Col>
							</ProjectStackRow>
							<ProjectStackRow>
								<Col flex="10rem">
									<StackSpan>
										<CheckOutlined style={{ paddingRight: 5 }} />
										State Management
									</StackSpan>
								</Col>
								<Col flex="auto">React Hook (uss, props)</Col>
							</ProjectStackRow>
						</ProjectContent>
					</Col>
				</ProjectRow>
			</ProjectCard>
		</ProjectFlexWrapper>
	)
}
