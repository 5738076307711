import MainLayout from 'components/common/Layout/MainLayout'
import { PortFolio } from 'components/component/portfolio/PortFolio'

export const MainPage = () => {
	return (
		<MainLayout>
			<PortFolio />
		</MainLayout>
	)
}
